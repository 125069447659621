/* DataTableDemo.css */

.datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
	
	
}

@media screen and (max-width: 1700px) {
	.p-dropdown-label {
     white-space: nowrap !important;
	}
}

.product-badge.status-naoativado {
    background: #ffcdd2;
    color: #c63737;
}

.product-badge.status-ativado {
    background: #c8e6c9;
    color: #256029;
}

.product-badge.status-bloqueado {
    background: #ffcdd2;
    color: #c63737;
}

.product-badge.status-encerrado {
    background: #000;
    color: #FFF;
}

.product-badge.status-review {
    background: orange;
    color: #FFF;
}

.product-badge.status-male {
    background: lightblue;
    color: #FFF;
} 

.product-badge.status-female {
    background: #ff99ff;
    color: #FFF;
}

.product-badge {
    border-radius: 5px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.p-button {
    margin-right: 0.5rem;
}
.p-buttonset .p-button {
    margin-right: 0;
}
.p-button i {
    line-height: 2.25rem;
}

.p-datatable-emptymessage td {

	font-size:18px;
	padding:20px !important;
	
}

.p-inputtext, .p-component {
	
   font-size: 0.9rem !important;
	
}

.p-datatable .p-datatable-thead > tr > th {
    padding: 1rem 0.5rem;	
}