/* DataTableDemo.css */

.datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
	
	
}

@media screen and (max-width: 1700px) {
	.p-dropdown-label {
     white-space: nowrap !important;
	}
}

.product-badge.status-naoativado {
    background: #ffcdd2;
    color: #c63737;
}

.product-badge.status-ativado {
    background: #c8e6c9;
    color: #256029;
}

.product-badge.status-agendado {
    background: whitesmoke;
    color: #256029;
}

.product-badge.status-mesmodia {
    background: lightyellow;
    color: #333300;
}

.product-badge.status-bloqueado {
    background: #ffcdd2;
    color: #c63737;
}

.product-badge.status-encerrado {
    background: #000;
    color: #FFF;
}

.product-badge.status-review {
    background: orange;
    color: #FFF;
}

.product-badge {
    border-radius: 5px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.p-button {
    margin-right: 0.5rem;
}
.p-buttonset .p-button {
    margin-right: 0;
}
.p-button i {
    line-height: 2.25rem;
}

.p-datatable-emptymessage td {

	font-size:18px;
	padding:20px !important;
	
}

.p-inputtext, .p-component {
	
   font-size: 0.9rem !important;
	
}

.p-datatable .p-datatable-thead > tr > th {
    padding: 1rem 0.5rem;	
}



.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 10%;
    z-index: 1;
} 

.custom-marker .active {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    z-index: 1;
}

.timeline-demo .p-timeline-event-content,
.timeline-demo .p-timeline-event-opposite {
    line-height: 1;
}

@media screen and (max-width: 960px) {
    .timeline-demo .customized-timeline .p-timeline-event:nth-child(even) {
        flex-direction: row !important;
    }
    .timeline-demo .customized-timeline .p-timeline-event:nth-child(even) .p-timeline-event-content {
        text-align: left !important;
    }
    .timeline-demo .customized-timeline .p-timeline-event-opposite {
        flex: 0;
    }
    .timeline-demo .customized-timeline .p-card {
        margin-top: 1rem;
    }
}

.p-timeline-horizontal .p-timeline-event:last-child {
    flex: 1 1;
}